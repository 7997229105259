export default {
    agents: [
        {
            id: 1,
            agentId: 1,
            name: 'Astra',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_astra.png',
            numberHave: 1852,
        },
        {
            id: 2,
            agentId: 2,
            name: 'Breach',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_breach.png',
            numberHave: 3242,
        },
        {
            id: 3,
            agentId: 3,
            name: 'Brimstone',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_brimstone.png',
            numberHave: 6452,
        },
        {
            id: 4,
            agentId: 4,
            name: 'Cyper',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_cyper.png',
            numberHave: 3234,
        },
        {
            id: 5,
            agentId: 5,
            name: 'Jett',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_jett.png',
            numberHave: 6545,
        },
        {
            id: 6,
            agentId: 6,
            name: 'Kay/o',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_kayo.png',
            numberHave: 1932,
        },
        {
            id: 7,
            agentId: 7,
            name: 'Killjoy',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_killjoy.png',
            numberHave: 8732,
        },
        {
            id: 8,
            agentId: 8,
            name: 'Omen',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_omen.png',
            numberHave: 8712,
        },
        {
            id: 9,
            agentId: 9,
            name: 'Phoneix',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_phoneix.png',
            numberHave: 7651,
        },
        {
            id: 10,
            agentId: 10,
            name: 'Raze',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_raze.png',
            numberHave: 1823
        },
        {
            id: 11,
            agentId: 11,
            name: 'Reyna',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_reyna.png',
            numberHave: 33263
        },
        {
            id: 12,
            agentId: 12,
            name: 'Sage',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_sage.png',
            numberHave: 9565
        },
        {
            id: 13,
            agentId: 13,
            name: 'Skye',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_skye.png',
            numberHave: 4354
        },
        {
            id: 14,
            agentId: 14,
            name: 'Sova',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_sova.png',
            numberHave: 6745
        },
        {
            id: 15,
            agentId: 15,
            name: 'Viper',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_viper.png',
            numberHave: 4598,
        },
        {
            id: 16,
            agentId: 16,
            name: 'Yoru',
            agentSurveyCategoryId: 1,
            agentPicture: 'val_yoru.png',
            numberHave: 3912,
        },
        //change
        {
            id: 17,
            agentId: 1,
            name: 'Kedi',
            agentSurveyCategoryId: 2,
            agentPicture: 'British_Shorthair_Cat.png',
            numberHave: 2212,
        },
        {
            id: 18,
            agentId: 2,
            name: 'Şarjlanmış Creeper',
            agentSurveyCategoryId: 2,
            agentPicture: 'Charged_Creeper.png',
            numberHave: 1212,
        },
        {
            id: 19,
            agentId: 3,
            name: 'Tavuk',
            agentSurveyCategoryId: 2,
            agentPicture: 'Chicken.png',
            numberHave: 7212,
        },
        {
            id: 20,
            agentId: 4,
            name: 'İnek',
            agentSurveyCategoryId: 2,
            agentPicture: 'Cow.png',
            numberHave: 4562,
        },
        {
            id: 21,
            agentId: 5,
            name: 'Papağan',
            agentSurveyCategoryId: 2,
            agentPicture: 'Dancing_parrot_JE1.gif',
            numberHave: 1459,
        },
        {
            id: 22,
            agentId: 6,
            name: 'Eşek',
            agentSurveyCategoryId: 2,
            agentPicture: 'Donkey.png',
            numberHave: 1459,
        },
        {
            id: 23,
            agentId: 7,
            name: 'Ender Dragon',
            agentSurveyCategoryId: 2,
            agentPicture: 'Ender_Dragon.gif',
            numberHave: 459,
        },
        {
            id: 24,
            agentId: 8,
            name: 'Ender Dragon',
            agentSurveyCategoryId: 2,
            agentPicture: 'Enderman.png',
            numberHave: 368,
        },
        {
            id: 25,
            agentId: 9,
            name: 'Tilki',
            agentSurveyCategoryId: 2,
            agentPicture: 'Fox.png',
            numberHave: 7341,
        },
        {
            id: 26,
            agentId: 10,
            name: 'Açık Gri Koyun',
            agentSurveyCategoryId: 2,
            agentPicture: 'Light_Gray_Sheep_JE2.png',
            numberHave: 78,
        },
        {
            id: 27,
            agentId: 11,
            name: 'Kutup Ayısı',
            agentSurveyCategoryId: 2,
            agentPicture: 'Polar_Bear_Revision_1.png',
            numberHave: 938,
        },
        {
            id: 27,
            agentId: 12,
            name: 'Cadı',
            agentSurveyCategoryId: 2,
            agentPicture: 'Witch.png',
            numberHave: 286,
        },

    ],
    surveyResult: [
        {
            id: 1,
            resultSurveyCategoryId: 1,
            name: 'Liderlik',
            icon: 'fab fa-superpowers',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 70,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-info',
            style: ''
        },
        {
            id: 2,
            resultSurveyCategoryId: 1,
            name: 'Taktik Yapısı',
            icon: 'fab fa-galactic-senate',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 30,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-warning',
            style: ''
        },
        {
            id: 3,
            resultSurveyCategoryId: 1,
            name: 'Sezgisellik',
            icon: 'fab fa-galactic-republic',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 20,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-success',
            style: ''
        },
        {
            id: 4,
            resultSurveyCategoryId: 1,
            name: 'Mantık Merkezli',
            icon: 'fas fa-brain',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 90,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-dark',
            style: ''
        },
        {
            id: 5,
            resultSurveyCategoryId: 1,
            name: 'Prensip Merkezli',
            icon: 'fas fa-mug-hot',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 40,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-primary',
            style: ''
        },
        {
            id: 6,
            resultSurveyCategoryId: 1,
            name: 'Temkinli Oynayışlılık',
            icon: 'fas fa-reply-all',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 60,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-danger',
            style: ''
        },
        //CHANGED CATEGORY
        {
            id: 7,
            resultSurveyCategoryId: 2,
            name: 'Gerçekçi oynayış',
            icon: 'fas fa-globe-americas',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 30,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-primary',
            style: ''
        },
        {
            id: 8,
            resultSurveyCategoryId: 2,
            name: 'Yapıda Gerçekçiliğe önem verme',
            icon: 'fas fa-shapes',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 30,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-dark',
            style: ''
        },
        {
            id: 9,
            resultSurveyCategoryId: 2,
            name: 'Yapıda Tasarıma önem verme',
            icon: 'fas fa-paint-brush',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 30,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-info',
            style: ''
        },
        {
            id: 10,
            resultSurveyCategoryId: 2,
            name: 'Modernlik',
            icon: 'fas fa-paint-brush',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 30,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-success',
            style: ''
        },
        {
            id: 11,
            resultSurveyCategoryId: 2,
            name: 'Gelişmeye önem verme',
            icon: 'fas fa-star',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 30,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-warning',
            style: ''
        },
        {
            id: 11,
            resultSurveyCategoryId: 2,
            name: 'Sistemsel Gelişmeye odaklanma',
            icon: 'fas fa-tractor',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ac nisi ut malesuada.',
            rate: 30,
            ratio: Math.floor(Math.random() * 70) + 20,
            class: 'bg-danger',
            style: ''
        },
    ]
};

<template>
 <div class="app">
   <router-view />
 </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
.app {
  /*font-family: PassionOne, sans-serif;*/

}

</style>
